import React from 'react'
import { Link } from 'react-router-dom'

import { Player } from '@lottiefiles/react-lottie-player'
import { Helmet } from 'react-helmet'

import FeatureCard2 from '../components/feature-card2'
import TeamCard from '../components/team-card'
import './home.css'

const Home = (props) => {
  return (
    <div id="home" className="home-container">
      <Helmet>
        <title>Apprison</title>
        <meta property="og:title" content="Apprison" />
      </Helmet>
      <section id="landing1" className="home-hero">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <a href="#home" className="home-link">
            <img alt="logo" src="/dm_logo-200h.jpg" className="home-image" />
          </a>
          <div data-thq="thq-navbar-nav" className="home-desktop-menu">
            <nav className="home-links">
              <span className="home-text">About</span>
              <a href="#features" className="home-link01">
                Features
              </a>
              <a href="#pricing" className="home-link02">
                Pricing
              </a>
              <span className="home-text001">Team</span>
              <Link to="/blog" className="home-text002">
                Blog
              </Link>
              <div data-thq="thq-burger-menu" className="home-burger-menu">
                <svg viewBox="0 0 1024 1024" className="home-icon">
                  <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </div>
            </nav>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div className="home-nav">
              <div className="home-top">
                <img
                  alt="image"
                  src="/dm_logo-200h.jpg"
                  className="home-logo"
                />
                <div data-thq="thq-close-menu" className="home-close-menu">
                  <svg viewBox="0 0 1024 1024" className="home-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="home-links1">
                <span className="home-text003">About</span>
                <span className="home-text004">Features</span>
                <span className="home-text005">Pricing</span>
                <span className="home-text006">Team</span>
                <span className="home-text007">Blog</span>
              </nav>
              <img
                alt="image"
                src="/google-play-badge-1500h.png"
                className="home-image1"
              />
              <div className="home-icon-group">
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="home-icon04"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon06"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="home-icon08"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
            </div>
          </div>
        </header>
        <span className="home-text008">Empower Your Digital Wellbeing</span>
        <span className="home-text009">
          <span>
            Are you ready to embrace the power of technology without
            compromising your mental and physical health?
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <br></br>
          <span>
            Introducing AP, the revolutionary smartphone app designed to empower
            you to achieve digital balance and unlock your true potential in the
            digital age.
          </span>
        </span>
        <img
          alt="image"
          src="/google-play-badge-1500h.png"
          className="home-image2"
        />
        <div className="home-container01">
          <a href="#home" className="home-link03 button">
            <svg viewBox="0 0 1024 1024" className="home-icon10">
              <path d="M298 598l214-214 214 214h-428z"></path>
            </svg>
          </a>
        </div>
      </section>
      <section id="landing2" className="home-hero2">
        <header data-thq="thq-navbar" className="home-navbar-interactive1">
          <Link to="/" className="home-navlink">
            <img
              alt="logo"
              src="/apprisonlogo8x8-1500h.png"
              className="home-image3"
            />
          </Link>
          <div data-thq="thq-navbar-nav" className="home-desktop-menu1">
            <nav className="home-links2">
              <a href="#about" className="home-link04">
                About
              </a>
              <a href="#features" className="home-link05">
                Features
              </a>
              <a href="#pricing" className="home-link06">
                Pricing
              </a>
              <span className="home-text014">Team</span>
              <Link to="/blog" className="home-navlink1">
                Blog
              </Link>
            </nav>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu1">
            <svg viewBox="0 0 1024 1024" className="home-icon12">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu1">
            <div className="home-nav1">
              <div className="home-top1">
                <img
                  alt="image"
                  src="/apprisonlogo8x8-1500h.png"
                  className="home-logo1"
                />
                <div data-thq="thq-close-menu" className="home-close-menu1">
                  <svg viewBox="0 0 1024 1024" className="home-icon14">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="home-links3">
                <a href="#about" className="home-link07">
                  About
                </a>
                <a href="#features" className="home-link08">
                  Features
                </a>
                <a href="#pricing" className="home-link09">
                  Pricing
                </a>
                <span className="home-text015">Team</span>
                <Link to="/blog" className="home-navlink2">
                  Blog
                </Link>
              </nav>
              <a
                href="https://play.google.com/store/apps/details?id=com.codenicely.apprison.app.prison"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link10"
              >
                <img
                  alt="image"
                  src="/google-play-badge-1500h.png"
                  className="home-image4"
                />
              </a>
              <div className="home-icon-group1">
                <a
                  href="https://twitter.com/digitalwellbei4"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="home-icon16"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/apprison.digitalwellbeing/?hl=en"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon18"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/apprison4u/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="home-icon20"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </header>
        <div className="home-container02">
          <div className="home-container03">
            <span className="home-text016">Empower Your Digital Wellbeing</span>
            <span className="home-text017">
              <span>
                Are you ready to embrace the power of technology without
                compromising your mental and physical health?
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <br></br>
              <span>
                Introducing
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text022">Apprison</span>
              <span>
                , the revolutionary smartphone app designed to empower you to
                achieve digital balance and unlock your true potential in the
                digital age.
              </span>
            </span>
            <a
              href="https://play.google.com/store/apps/details?id=com.codenicely.apprison.app.prison"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link14"
            >
              <img
                alt="image"
                src="/google-play-badge-1500h.png"
                className="home-image5"
              />
            </a>
          </div>
          <Player
            src="https://lottie.host/f81e3dc5-3be3-4ab0-a90e-d7b40f84de6f/eSqmGDNUPv.json"
            loop
            speed="1"
            autoplay
            background="transparent"
            className="home-lottie"
          ></Player>
        </div>
        <div className="home-container04">
          <a href="#home" className="home-link15 button">
            <svg viewBox="0 0 1024 1024" className="home-icon22">
              <path d="M298 598l214-214 214 214h-428z"></path>
            </svg>
          </a>
        </div>
      </section>
      <section id="features" className="home-features">
        <h1 className="home-heading">
          <span>The Power of Apprison</span>
          <br></br>
        </h1>
        <span className="home-text026">
          <span>
            We are committed to creating a safe and supportive environment for
            your digital journey. We prioritize your privacy and security,
            ensuring your personal data remains confidential and protected at
            all times
          </span>
          <br></br>
        </span>
        <div className="home-container05">
          <Player
            src="https://lottie.host/28504f00-26f9-42f2-9cc6-cf298c9130e1/sEg50zxHXr.json"
            loop
            speed="1"
            autoplay
            background="transparent"
            className="home-lottie-node"
          ></Player>
          <div className="home-container06">
            <FeatureCard2
              title="Track Your Digital Habits"
              description="Gain deep insights into your smartphone usage patterns, screen time, and app dependencies. AP provides comprehensive analytics to help you understand your habits and make informed decisions"
              rootClassName="rootClassName"
            ></FeatureCard2>
            <FeatureCard2
              title="Set Healthy Boundaries"
              description="Establish boundaries that align with your wellbeing goals. With AP, you can set time limits for specific apps, create designated screen-free zones, and receive gentle reminders to take breaks, fostering a healthier relationship with your device"
              rootClassName="rootClassName3"
            ></FeatureCard2>
            <FeatureCard2
              title="Foster Mindful Engagement"
              description="AP empowers you to be present in the moment and fully engage with the world around you. Through mindful features, such as notification filters, focus modes, and mindful reminders, AP helps you cultivate mindfulness and reduce distractions."
              rootClassName="rootClassName2"
            ></FeatureCard2>
            <FeatureCard2
              title="Boost Your Productivity"
              description="AP equips you with productivity-enhancing features to maximize your focus and efficiency. Utilize task management tools, customizable workflows, and time-blocking techniques to achieve your goals and conquer your to-do lists"
              rootClassName="rootClassName1"
            ></FeatureCard2>
          </div>
        </div>
      </section>
      <section id="about" className="home-about-us">
        <h1 className="home-heading1">
          <span>ABOUT US</span>
          <br></br>
        </h1>
        <div className="home-container07">
          <TeamCard
            name="Jagrit Surisetti"
            role="FOUNDER"
            quote="Tech Leaders Fellow"
            quote1=" University of California Berkeley &amp; Purdue University"
            picture_src="/jagritsurisetti-300h.jpg"
            rootClassName="rootClassName2"
          ></TeamCard>
          <TeamCard
            name="Jayesh Surisetti"
            role="Co-Founder"
            quote="IIM Gold Medalist"
            quote1="5+ years of Corporate experience in Strategy &amp; HR"
            picture_src="/chg3t4dn_400x400-300h.jpg"
            rootClassName="rootClassName"
          ></TeamCard>
        </div>
      </section>
      <section id="pricing" className="home-pricing">
        <img alt="image" src="/flowl-900w.png" className="home-image6" />
        <img alt="image" src="/flowr-400w.png" className="home-image7" />
        <div className="home-container08">
          <div className="home-container09">
            <h1 className="home-text031">Pricing Plans</h1>
          </div>
          <div className="home-container10">
            <div className="home-container11">
              <div className="home-container12">
                <span className="home-text032">Quarterly</span>
                <span className="home-text033">$1</span>
              </div>
              <div className="home-container13">
                <span className="home-text034">
                  <span className="home-text035">99</span>
                  <span> Components</span>
                </span>
                <span className="home-text037">
                  <span className="home-text038">3</span>
                  <span> Collaborators</span>
                </span>
                <span className="home-text040">
                  <span className="home-text041">Public projects</span>
                </span>
                <span className="home-text042">
                  <span className="home-text043">10</span>
                  <span> Projects</span>
                </span>
                <button className="home-button button">Learn More</button>
              </div>
            </div>
            <div className="home-container14">
              <div className="home-container15">
                <span className="home-text045">Yearly</span>
                <span className="home-text046">$10</span>
              </div>
              <div className="home-container16">
                <span className="home-text047">
                  <span className="home-text048">100</span>
                  <span> Projects</span>
                </span>
                <span className="home-text050">
                  <span className="home-text051">1000</span>
                  <span> Components</span>
                </span>
                <span className="home-text053">
                  <span className="home-text054">20</span>
                  <span> Collaborators</span>
                </span>
                <span className="home-text056">
                  <span className="home-text057">Private projects</span>
                </span>
                <button className="home-button1 button">Learn More</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="pricing2" className="home-pricing2">
        <div className="home-container17">
          <div className="home-container18">
            <h1 className="home-text058">Pricing Plans</h1>
            <span className="home-text059">
              <span>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                  lorem lorem, malesuada in metus vitae, scelerisque accumsan
                  ipsum.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
          </div>
          <div className="home-container19">
            <div className="home-container20">
              <span className="home-text063">Quarterly</span>
              <span className="home-text064">$1</span>
              <span className="home-text065">
                <span className="home-text066">10</span>
                <span> Projects</span>
              </span>
              <span className="home-text068">
                <span className="home-text069">99</span>
                <span> Components</span>
              </span>
              <span className="home-text071">
                <span className="home-text072">3</span>
                <span> Collaborators</span>
              </span>
              <span className="home-text074">
                <span className="home-text075">Public projects</span>
              </span>
              <button className="home-button2 button">Learn More</button>
            </div>
            <div className="home-container21">
              <span className="home-text076">Yearly</span>
              <span className="home-text077">$10</span>
              <span className="home-text078">
                <span className="home-text079">100</span>
                <span> Projects</span>
              </span>
              <span className="home-text081">
                <span className="home-text082">1000</span>
                <span> Components</span>
              </span>
              <span className="home-text084">
                <span className="home-text085">20</span>
                <span> Collaborators</span>
              </span>
              <span className="home-text087">
                <span className="home-text088">Private projects</span>
              </span>
              <button className="home-button3 button">Learn More</button>
            </div>
          </div>
        </div>
      </section>
      <section className="home-pricing3">
        <div className="home-container22">
          <div className="home-container23">
            <h1 className="home-text089">Pricing Plans</h1>
            <span className="home-text090">
              <span>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                  lorem lorem, malesuada in metus vitae, scelerisque accumsan
                  ipsum.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
          </div>
          <div className="home-container24">
            <div className="home-container25">
              <span className="home-text094">Free</span>
              <span className="home-text095">$0</span>
              <span className="home-text096">
                <span className="home-text097">10</span>
                <span> Projects</span>
              </span>
              <span className="home-text099">
                <span className="home-text100">99</span>
                <span> Components</span>
              </span>
              <span className="home-text102">
                <span className="home-text103">3</span>
                <span> Collaborators</span>
              </span>
              <span className="home-text105">
                <span className="home-text106">Public projects</span>
              </span>
              <button className="home-button4 button">Learn More</button>
            </div>
            <div className="home-container26">
              <span className="home-text107">Free</span>
              <span className="home-text108">$29</span>
              <span className="home-text109">
                <span className="home-text110">100</span>
                <span> Projects</span>
              </span>
              <span className="home-text112">
                <span className="home-text113">1000</span>
                <span> Components</span>
              </span>
              <span className="home-text115">
                <span className="home-text116">20</span>
                <span> Collaborators</span>
              </span>
              <span className="home-text118">
                <span className="home-text119">Private projects</span>
              </span>
              <button className="home-button5 button">Learn More</button>
            </div>
          </div>
        </div>
      </section>
      <footer className="home-footer">
        <div className="home-container27">
          <Link to="/" className="home-navlink3">
            <img
              alt="logo"
              src="/apprisonlogo8x8-1500h.png"
              className="home-image8"
            />
          </Link>
          <nav className="home-nav2">
            <a href="#about" className="home-link16">
              About
            </a>
            <a href="#features" className="home-link17">
              Features
            </a>
            <a href="#pricing" className="home-link18">
              Pricing
            </a>
            <span className="home-text120">Team</span>
            <span className="home-text121">Blog</span>
          </nav>
        </div>
        <div className="home-separator"></div>
        <div className="home-container28">
          <span className="home-text122">info@apprison.app</span>
          <div className="home-icon-group2">
            <a
              href="https://twitter.com/digitalwellbei4"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link19"
            >
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon24">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/apprison.digitalwellbeing/?hl=en"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link20"
            >
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon26">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/apprison4u/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link21"
            >
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon28">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Home
