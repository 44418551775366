import React from 'react'
import { Link } from 'react-router-dom'

import { Player } from '@lottiefiles/react-lottie-player'
import { Helmet } from 'react-helmet'

import BlogPostCard1 from '../components/blog-post-card1'
import './blog.css'

const Blog = (props) => {
  return (
    <div className="blog-container">
      <Helmet>
        <title>Blog - Apprison</title>
        <meta property="og:title" content="Blog - Apprison" />
      </Helmet>
      <section id="bloglanding" className="blog-hero2">
        <header data-thq="thq-navbar" className="blog-navbar-interactive">
          <Link to="/" className="blog-navlink">
            <img alt="logo" src="/dm_logo-1500h.jpg" className="blog-image" />
          </Link>
          <div data-thq="thq-navbar-nav" className="blog-desktop-menu">
            <nav className="blog-links">
              <span className="blog-text">About</span>
              <a href="#features" className="blog-link">
                Features
              </a>
              <a href="#pricing" className="blog-link1">
                Pricing
              </a>
              <span className="blog-text01">Team</span>
              <Link to="/blog" className="blog-navlink1">
                Blog
              </Link>
            </nav>
          </div>
          <div data-thq="thq-burger-menu" className="blog-burger-menu">
            <svg viewBox="0 0 1024 1024" className="blog-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="blog-mobile-menu">
            <div className="blog-nav">
              <div className="blog-top">
                <img
                  alt="image"
                  src="/dm_logo-1500h.jpg"
                  className="blog-logo"
                />
                <div data-thq="thq-close-menu" className="blog-close-menu">
                  <svg viewBox="0 0 1024 1024" className="blog-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="blog-links1">
                <span className="blog-text02">About</span>
                <a href="#features" className="blog-link2">
                  Features
                </a>
                <a href="#pricing" className="blog-link3">
                  Pricing
                </a>
                <span className="blog-text03">Team</span>
                <Link to="/blog" className="blog-navlink2">
                  Blog
                </Link>
              </nav>
              <img
                alt="image"
                src="/google-play-badge-1500h.png"
                className="blog-image1"
              />
              <div className="blog-icon-group">
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="blog-icon04"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="blog-icon06"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="blog-icon08"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
            </div>
          </div>
        </header>
        <div className="blog-container01">
          <span className="blog-text04">Empower Your Digital Wellbeing</span>
          <img
            alt="image"
            src="/google-play-badge-1500h.png"
            className="blog-image2"
          />
        </div>
        <img alt="image" src="/blog1-1200h.png" className="blog-image3" />
      </section>
      <div className="blog-pricing">
        <img alt="image" src="/blog2-200h.png" className="blog-image4" />
        <div className="blog-container02">
          <h1 className="blog-text05">Digital Health</h1>
          <h1 className="blog-text06">
            Nurturing Digital Well-being: A Guide to Maintaining Healthy
            Smartphone Usage
          </h1>
          <span className="blog-text07">
            <span>
              In today&apos;s digital age, smartphones have become an integral
              part of our lives. These pocket-sized devices offer a plethora of
              functionalities, connecting us to the world at our fingertips.
              However, with their increasing ubiquity, it is crucial to develop
              a mindful approach towards smartphone usage to safeguard our
              mental and physical well-being. In this blog post, we will explore
              practical tips and strategies to maintain digital health while
              making the most of our smartphones.
            </span>
            <br className="blog-text09"></br>
            <br className="blog-text10"></br>
            <br></br>
            <span className="blog-text12">1. Establish Boundaries</span>
            <br></br>
            <br></br>
            <span>
              Creating boundaries around smartphone usage is essential for
              promoting a healthy relationship with technology. Set specific
              time limits and allocate designated periods for focused work,
              quality time with loved ones, relaxation, and rest. Establish
              phone-free zones or activities to encourage uninterrupted
              face-to-face interactions and enhance productivity.
            </span>
            <br className="blog-text16"></br>
            <br></br>
            <br></br>
            <span className="blog-text19">
              2. Curate a Purposeful Digital Environment
            </span>
            <br className="blog-text20"></br>
            <br></br>
            <span>
              Take control of your smartphone&apos;s content and notifications.
              Review and declutter your apps regularly, removing those that no
              longer serve you. Customize notifications to minimize distractions
              and prioritize essential alerts. Consider limiting social media
              usage by uninstalling or disabling notifications for platforms
              that tend to consume excessive time and attention.
            </span>
            <br className="blog-text23"></br>
            <br></br>
            <br></br>
            <span className="blog-text26">3. Practice Mindful Engagement</span>
            <br className="blog-text27"></br>
            <br></br>
            <span>
              Engage with your smartphone mindfully, being aware of the
              intention behind each interaction. Ask yourself whether the
              activity aligns with your goals or brings you joy. Be present in
              the moment, rather than mindlessly scrolling or multitasking.
              Mindful engagement allows you to appreciate the benefits of
              technology while preventing it from dominating your attention.
            </span>
            <br className="blog-text30"></br>
            <br></br>
            <br></br>
            <span className="blog-text33">
              4. Foster Digital Well-being Habits
            </span>
            <br className="blog-text34"></br>
            <br></br>
            <span>
              Take proactive steps to enhance your well-being in the digital
              realm. Prioritize self-care by incorporating periods of digital
              detox or screen-free activities into your routine. Engage in
              physical exercise, creative pursuits, or spend time in nature to
              counterbalance sedentary smartphone use. Implement good ergonomic
              practices by maintaining proper posture and taking breaks to
              prevent physical strain.
            </span>
            <br className="blog-text37"></br>
            <br></br>
            <br className="blog-text39"></br>
            <br></br>
            <span>Establish Healthy Sleep Patterns:</span>
            <br className="blog-text42"></br>
            <br></br>
            <span>
              Smartphone usage before bedtime can disrupt sleep patterns and
              lead to insomnia. Create a nighttime routine that includes
              disconnecting from your device at least an hour before sleep.
              Avoid using smartphones as alarm clocks, as it tempts you to check
              notifications right before sleep. Instead, opt for a traditional
              alarm clock and keep your smartphone away from the bedroom to
              foster better sleep hygiene.
            </span>
            <br className="blog-text45"></br>
            <br></br>
            <br className="blog-text47"></br>
            <br></br>
            <span>Cultivate Digital Boundaries in Relationships:</span>
            <br className="blog-text50"></br>
            <br></br>
            <span>
              Maintaining healthy relationships with loved ones requires setting
              digital boundaries. Engage in open discussions with friends and
              family about smartphone usage etiquette and establish mutually
              agreed-upon guidelines. Designate quality time where smartphones
              are set aside, fostering meaningful connections and reducing the
              impact of digital distractions.
            </span>
            <br className="blog-text53"></br>
            <br></br>
            <br className="blog-text55"></br>
            <br></br>
            <span>Utilize Digital Well-being Tools:</span>
            <br className="blog-text58"></br>
            <br></br>
            <span>
              Many smartphones now offer built-in digital well-being features,
              allowing users to monitor and manage their screen time. Explore
              these tools and leverage features like app timers, screen time
              tracking, and notification settings to gain insights into your
              usage patterns and make informed decisions about managing your
              digital engagement.
            </span>
            <br className="blog-text61"></br>
            <br></br>
            <br className="blog-text63"></br>
            <br></br>
            <span>Conclusion:</span>
            <br className="blog-text66"></br>
            <br></br>
            <span>
              Smartphone usage plays a significant role in our lives, and
              maintaining digital health is essential to foster a harmonious
              relationship with technology. By establishing boundaries,
              cultivating mindful engagement, and incorporating healthy habits,
              we can harness the benefits of smartphones while safeguarding our
              mental and physical well-being. Remember, a conscious approach
              towards smartphone usage empowers us to create a balanced digital
              lifestyle that enhances our overall quality of life.
            </span>
          </span>
          <button type="button" className="blog-button button">
            Read More
          </button>
        </div>
        <div className="blog-blog">
          <div className="blog-container03">
            <BlogPostCard1 rootClassName="rootClassName3"></BlogPostCard1>
          </div>
          <div className="blog-container04">
            <BlogPostCard1
              image_src="https://images.unsplash.com/photo-1465925508512-1e7052bb62e6?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHxjaXR5JTIwY2FifGVufDB8fHx8MTYyNjQ1MDMwNA&amp;ixlib=rb-1.2.1&amp;h=1200"
              rootClassName="rootClassName2"
            ></BlogPostCard1>
          </div>
        </div>
      </div>
      <div className="blog-pricing1">
        <div className="blog-container05">
          <Player
            src="https://assets7.lottiefiles.com/packages/lf20_k56tZILcig.json"
            loop
            speed="1"
            autoplay
            background="transparent"
            className="blog-lottie-node"
          ></Player>
        </div>
        <div className="blog-container06">
          <h1 className="blog-heading">60%</h1>
          <h1 className="blog-text69">Heading</h1>
        </div>
        <div className="blog-container07">
          <h1 className="blog-heading1">60%</h1>
          <h1 className="blog-text70">Heading</h1>
        </div>
      </div>
      <footer className="blog-footer">
        <div className="blog-container08">
          <img alt="logo" src="/dm_logo-1500h.jpg" className="blog-image5" />
          <nav className="blog-nav1">
            <span className="blog-text71">About</span>
            <span className="blog-text72">Features</span>
            <span className="blog-text73">Pricing</span>
            <span className="blog-text74">Team</span>
            <span className="blog-text75">Blog</span>
          </nav>
        </div>
        <div className="blog-separator"></div>
        <div className="blog-container09">
          <span className="blog-text76">
            © 2021 teleportHQ, All Rights Reserved.
          </span>
          <div className="blog-icon-group1">
            <svg viewBox="0 0 950.8571428571428 1024" className="blog-icon10">
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg viewBox="0 0 877.7142857142857 1024" className="blog-icon12">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg viewBox="0 0 602.2582857142856 1024" className="blog-icon14">
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Blog
