import React from 'react'

import PropTypes from 'prop-types'

import './team-card.css'

const TeamCard = (props) => {
  return (
    <div className={`team-card-testimonial-card ${props.rootClassName} `}>
      <div className="team-card-testimonial">
        <img
          alt={props.picture_alt}
          src={props.picture_src}
          className="team-card-image"
        />
        <span className="team-card-text">{props.name}</span>
        <span className="team-card-text1">{props.role}</span>
        <span className="team-card-text2">{props.quote}</span>
        <span className="team-card-text3">{props.quote1}</span>
      </div>
    </div>
  )
}

TeamCard.defaultProps = {
  picture_alt: 'profile',
  name: 'Jane Doe',
  quote:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.  Nam pellentesque nulla leo, sagittis vehicula sem commodo nec.',
  quote1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.  Nam pellentesque nulla leo, sagittis vehicula sem commodo nec.',
  role: 'SOFTWARE ENGINEER',
  picture_src:
    'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&ixlib=rb-1.2.1&w=300',
  rootClassName: '',
}

TeamCard.propTypes = {
  picture_alt: PropTypes.string,
  name: PropTypes.string,
  quote: PropTypes.string,
  quote1: PropTypes.string,
  role: PropTypes.string,
  picture_src: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default TeamCard
